import HeroImage from '../assets/hero.png';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { Link } from 'react-scroll';

const Home = () => {
  return (
    <div
      name='home'
      className=' h-screen w-full bg-gradient-to-b from-black via-black to-gray-800 '
    >
      <div className='max-w-screen-xl mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row '>
        <div className='flex flex-col justify-center sm:h-full '>
          <h2 className='text-xl sm:text-7xl text-white font-bold  md:mt-24 lg:mx-12'>
            I'm a Web Developer
          </h2>
          <p className='text-gray-500 py-4 mx-w-md lg:ml-12'>
            Recent graduate with a passion for software development. I love to
            work on web application using technologies like React, Tailwind, and
            Node JS.
          </p>
          <div>
            <Link
              to='portfolio'
              smooth
              duration={800}
              className='group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-sky-700 to-indigo-950 cursor-pointer lg:mx-auto'
            >
              Portfolio
              <span className=' group-hover:rotate-90 duration-300'>
                <MdOutlineKeyboardArrowRight size={25} className='ml-1' />
              </span>
            </Link>
          </div>
        </div>
        <div>
          <img
            src={HeroImage}
            alt='hero'
            className='rounded-full mx-auto w-fit  md:w-full md:mt-20 lg:w-full'
          />
        </div>
      </div>
    </div>
  );
};
export default Home;
