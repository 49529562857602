const About = () => {
  return (
    <div
      name='about'
      className='w-full bg-gradient-to-b from-gray-800 to-black text-white'
    >
      <div className='max-w-screen-xl p-4 mx-auto flex flex-col justify-center w-full  '>
        <div className='pb-4 '>
          <p className='text-4xl font-bold inline border-b-4 border-gray-500 lg:ml-12 '>
            About
          </p>
        </div>
        <p className='sm:text-xl mt-2 mx-auto lg:ml-12'>
          Hi, I'm a frontend developer located in Vancouver Canada. As a
          developer, I possess a strong understanding of web development
          technologies, with a particular emphasis on HTML, CSS, and JavaScript.
          I have a keen eye for design and are able to transform mockups and
          wireframes into engaging, intuitive user interfaces that meet client
          needs and drive user engagement. My experience as a frontend developer
          has enabled me to develop strong skills in responsive design and
          mobile-first development, allowing me to create web applications that
          are accessible and user-friendly on a wide range of devices.
        </p>
        <br />
        <p className=' sm:text-xl lg:ml-12'>
          Additionally, I have experience with frontend framework such as React,
          as well as experience with backend technologies like Node.js. I am
          passionate about web development and enjoy staying up-to-date with the
          latest trends and technologies in the industry.Overall, my passion for
          web development, and technical expertise, make me a valuable asset to
          any team.
        </p>
      </div>
    </div>
  );
};
export default About;
