import html from '../assets/html.png';
import css from '../assets/css.png';
import javascript from '../assets/javascript.png';
import react from '../assets/react.png';
import github from '../assets/github.png';
import tailwind from '../assets/tailwind.png';
import git from '../assets/git.png';
import gitlab from '../assets/gitlab.png';
import playwright from '../assets/playwright.png';

const Experience = () => {
  const technologies = [
    {
      id: 1,
      src: html,
      title: 'HTML',
      style: 'shadow-orange-500',
    },
    {
      id: 2,
      src: css,
      title: 'CSS',
      style: 'shadow-blue-500',
    },
    {
      id: 3,
      src: javascript,
      title: 'JavaScript',
      style: 'shadow-yellow-500',
    },
    {
      id: 4,
      src: react,
      title: 'React',
      style: 'shadow-blue-700',
    },
    {
      id: 5,
      src: tailwind,
      title: 'Tailwind CSS',
      style: 'shadow-sky-400',
    },
    {
      id: 6,
      src: gitlab,
      title: 'GitLab',
      style: 'shadow-white',
    },
    {
      id: 7,
      src: git,
      title: 'Git',
      style: 'shadow-red-900',
    },
    {
      id: 8,
      src: github,
      title: 'GitHub',
      style: 'shadow-orange-500',
    },
    {
      id: 9,
      src: playwright,
      title: 'Playwright',
      style: 'shadow-green-500',
    },
  ];
  return (
    <div
      name='experience'
      className='bg-gradient-to-b from-gray-800 to-black  '
    >
      <div className='  max-w-screen-xl  mx-auto   p-4 flex flex-col justify-center w-full text-white '>
        <div>
          <p className='text-4xl font-bold border-b-4 border-gray-500 p-2 inline  lg:ml-12 '>
            Experience
          </p>
          <p className='py-6 lg:ml-12'>
            These are the technologies I am familiar with
          </p>
        </div>

        <div className='w-full  grid grid-cols-2 sm:grid-cols-3  gap-4 mx-auto text-center py-8 px-2 sm:px-4 lg:px-12 '>
          {technologies.map(({ id, src, title, style }) => (
            <div
              key={id}
              className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg  ${style}`}
            >
              <img src={src} alt={title} className='w-20 mx-auto ' />
              <p className='mt-4'>{title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Experience;
