const Contact = () => {
  return (
    <div
      name='contact'
      className='h-full w-full  bg-gradient-to-b from-black to-gray-800 p-4 text-white'
    >
      <div
        className='max-w-screen-xl flex flex-col p-4 justify-center  mx-auto  h-full
      '
      >
        <div className=' pb-7-8 '>
          <p className='text-4xl font-bold border-b-4 inline border-gray-500 lg:ml-12'>
            Contact
          </p>
          <p className='py-4 lg:ml-12'>Get in touch</p>
        </div>

        <div className=' flex justify-center items-center '>
          <form
            method='POST'
            action='https://getform.io/f/4df3c7e8-fc64-45ba-a461-cbf831b087e2'
            className='flex flex-col w-full md:w-1/2 '
          >
            <input
              type='text'
              name='name'
              required
              placeholder='Enter your name'
              className='p-2 bg-transparent border-2 rounded-md text-white focus:outline-none'
            />

            <input
              type='text'
              name='email'
              required
              placeholder='Enter your email'
              className='my-4 p-2 bg-transparent border-2 rounded-md text-white focus:outline-none'
            />

            <textarea
              name='message'
              placeholder='Enter your message'
              rows='10'
              required
              className='p-2 bg-transparent border-2 rounded-md text-white focus:outline-none'
            ></textarea>

            <button className='text-white bg-gradient-to-b from-sky-700 to-indigo-950 px-6 py-3 my-8 mx-auto flex items-center rounded-md hover:scale-110 duration-300'>
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Contact;
