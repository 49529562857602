import gadgets from '../assets/portfolio/gadgets.jpg';
import jobTracker from '../assets/portfolio/jobTracker.jpg';
import news from '../assets/portfolio/news.jpg';
import githubSearchUser from '../assets/portfolio/githubSearchUser.jpg';
import quiz from '../assets/portfolio/quiz.jpg';
import notes from '../assets/portfolio/notes.jpg';
import hex from '../assets/portfolio/hex.jpg';
import { FaGithub } from 'react-icons/fa';
import { VscLink } from 'react-icons/vsc';

const Portfolio = () => {
  const portfolios = [
    {
      id: 1,
      src: gadgets,
      demo: 'https://react-comfy-onlinestore.netlify.app/',
      linkIcon: 'VscLink',
      code: 'https://github.com/CyrusM3/react-e-commerce-comfy-Smoth-store',
      githubIcon: 'FaGithub',
      title: 'Gadgets E-commerce',
    },
    {
      id: 2,
      src: jobTracker,
      demo: 'https://job-management-tracker.netlify.app/',
      linkIcon: 'bx bx-link-alt',
      code: 'https://github.com/CyrusM3/jobster',
      githubIcon: 'bx bxl-github',
      title: 'Job Tracker App',
    },
    {
      id: 3,
      src: news,
      demo: 'https://hacker-newsland.netlify.app/',
      linkIcon: 'bx bx-link-alt',
      code: 'https://github.com/CyrusM3/react-hacker-news.git',
      githubIcon: 'bx bxl-github',
      title: 'Hacker News Land',
    },
    {
      id: 4,
      src: githubSearchUser,
      demo: 'https://react-github-finer.netlify.app/login',
      linkIcon: 'bx bx-link-alt',
      code: 'https://github.com/CyrusM3/react-github-user',
      githubIcon: 'bx bxl-github',
      title: 'GitHub Users',
    },

    {
      id: 5,
      src: quiz,
      demo: 'https://quiz-choice.netlify.app/',
      linkIcon: 'bx bx-link-alt',
      code: 'https://github.com/CyrusM3/react-quiz-app',
      githubIcon: 'bx bxl-github',
      title: 'Quiz App',
    },
    {
      id: 6,
      src: notes,
      demo: 'https://react-purchase-list.netlify.app/',
      linkIcon: 'bx bx-link-alt',
      code: 'https://github.com/CyrusM3/react-e-commerce-comfy-Smoth-store',
      githubIcon: 'bx bxl-github',
      title: 'Shopping List',
    },
    {
      id: 7,
      src: hex,
      demo: 'https://hexa-color-enerator.netlify.app/',
      linkIcon: 'bx bx-link-alt',
      code: 'https://github.com/CyrusM3/react-color-generator',
      githubIcon: 'bx bxl-github',
      title: 'Hex Color Generator',
    },
  ];

  return (
    <div
      name='portfolio'
      className='bg-gradient-to-b from-black to-gray-800 w-full text-white sm:overflow-y-scroll '
    >
      <div className='max-w-screen-xl p-4 mx-auto flex flex-col justify-center w-full lg:mx-auto'>
        <div className='pb-8'>
          <p className='text-4xl font-bold inline border-b-4 border-gray-500 lg:ml-12'>
            Portfolio
          </p>
          <p className='py-6 lg:ml-12'>Checkout some of my works right here</p>
        </div>

        {/* cards */}
        <div className='mx-auto grid grid-cols-1 px-1 sm:grid-cols-2  md:grid-cols-3 gap-8 sm:px-4 lg:px-12'>
          {portfolios.map(({ id, src, demo, code, title }) => (
            <div
              key={id}
              className='shadow-md shadow-gray-600 rounded-xl overflow-hidden'
            >
              <img
                src={src}
                alt={id}
                className='rounded-t-md duration-200 hover:scale-105'
              />
              <div className='flex justify-center mt-1'>{title}</div>
              <div className='flex items-center justify-center '>
                <button className='w-1/2 px-6 py-3 duration-100 '>
                  <a href={demo} target='_blank' rel='noreferrer'>
                    <VscLink
                      size={25}
                      className='mx-auto hover:scale-105 hover:text-indigo-200 '
                    />
                  </a>
                </button>

                <button className='w-1/2 px-6 py-3 duration-100 '>
                  <a href={code} target='_blank' rel='noreferrer '>
                    <FaGithub
                      size={25}
                      className='mx-auto hover:scale-105 hover:text-orange-300 '
                    />
                  </a>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Portfolio;
